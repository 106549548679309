import { t } from "i18n:astro";
import { useCallback } from "react";
import ExpandableSection from "../../../components/layout/ExpandableSection/ExpandableSection";
import { ExpandableSectionSize } from "../../../components/layout/ExpandableSection/types";
import { ExpandableId } from "../../../data/expandable";
import { ElementTheme } from "../../../types/theme";

const expandableConfig = {
  size: ExpandableSectionSize.STANDARD,
  theme: ElementTheme.DARK,
};

const ValueDelivery = () => {
  const renderList = useCallback(
    (vals: string[]) => (
      <ul>
        {vals.map((v, i) => (
          <li key={i}>
            <span>{v}</span>
          </li>
        ))}
      </ul>
    ),
    [t],
  );
  return (
    <>
      <ExpandableSection
        id={ExpandableId.DELIVERY_1}
        {...expandableConfig}
        title={t("home:value-delivery.expandable.acquisition.title")}
      >
        {renderList([
          t("home:value-delivery.expandable.acquisition.content.0"),
          t("home:value-delivery.expandable.acquisition.content.1"),
          t("home:value-delivery.expandable.acquisition.content.2"),
          t("home:value-delivery.expandable.acquisition.content.3"),
          t("home:value-delivery.expandable.acquisition.content.4"),
          t("home:value-delivery.expandable.acquisition.content.5"),
        ])}
      </ExpandableSection>

      <ExpandableSection
        id={ExpandableId.DELIVERY_2}
        {...expandableConfig}
        title={t("home:value-delivery.expandable.sell.title")}
      >
        {renderList([
          t("home:value-delivery.expandable.sell.content.0"),
          t("home:value-delivery.expandable.sell.content.1"),
          t("home:value-delivery.expandable.sell.content.2"),
          t("home:value-delivery.expandable.sell.content.3"),
        ])}
      </ExpandableSection>
    </>
  );
};

export default ValueDelivery;
